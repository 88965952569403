<template>
    <div class="card">
        <TabView class="px-1" @tab-change="changeTitle($event)" :activeIndex="activeIndex">
            <TabPanel header="Sumber Dana">
            </TabPanel>
            <TabPanel header="Rekening Penampung">
            </TabPanel>
            <TabPanel header="Biaya Transfer">
            </TabPanel>
            <TabPanel header="Menu">
            </TabPanel>
            <TabPanel header="Servis">
            </TabPanel>
            <TabPanel header="Mynt Binding">
            </TabPanel>
        </TabView>
        <router-view />
    </div>
</template>

<script>

export default {
    data() {
        return {
            activeIndex: 0,
        }
    },
    watch: {
        '$route': 'fetchLink',
    },
    mounted() {
        this.fetchLink()
    },
    methods: {
        fetchLink() {
            let pathName = this.$route.name
            if (pathName === 'settings-mynt-binding') {
                this.activeIndex = 5
            } else if (pathName === 'settings-service') {
                this.activeIndex = 4
            } else if (pathName === 'settings-menu') {
                this.activeIndex = 3
            } else if (pathName === 'settings-transfer-fee') {
                this.activeIndex = 2
            } else if (pathName === 'settings-holding-account') {
                this.activeIndex = 1
            } else {
                this.activeIndex = 0
            }
        },
        changeTitle(event) {
            if (event.index === 0) {
                this.$router.push({name: 'settings-source-of-fund'})
            } else if (event.index === 1) {
                this.$router.push({name: 'settings-holding-account'})
            } else if (event.index === 2) {
                this.$router.push({name: 'settings-transfer-fee'})
            } else if (event.index === 3) {
                this.$router.push({name: 'settings-menu'})
            } else if (event.index === 4) {
                this.$router.push({name: 'settings-service'})
            } else if (event.index === 5) {
                this.$router.push({name: 'settings-mynt-binding'})
            }
        },
    },
}
</script>

<style lang="scss" scoped>

</style>